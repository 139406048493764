<template>
  <div class="card stage-list">
    <b-row class="g-0" no-gutters>
      <b-col md="3" class="stage-list-data">
        <div ref="stageTitle" class="d-flex align-items-center justify-content-between p-3 pl-3 pb-2 stage-title-main">
          <div class="stage-title">{{'Stage'}}</div>
        </div>
        <div v-if="items.length > 0">
          <div v-for="(item, index) in items" :key="index">
            <div class="app-border p-3 position-relative active-element stage-card d-flex justify-content-between "
              :class="{ 'active': activeIndex === index,
              'first-item': isFirstItem(index) }" @click="toggleActive(index, $event)">
              <div class="content-container">
                <div class="mb-2 name"><b>{{ item.name }}</b></div>
                <div class="font-400 gray-600-color pre-md-text"
                  :class="{ 'text-dark': activeIndex === index}">
                  {{ item.jobTitle }}<span class="bullet-list"></span>{{ item.jobs }}</div>
              </div>
              <base-switch class="align-self-center custom-switch" v-model="item.isEnabled"
              onText="On" offText="Off"></base-switch>
            </div>
          </div>
        </div>
        <div v-if="items.length == 0">
          <NoIntegrate />
        </div>
      </b-col>
      <b-col v-if="activeIndex >= 0 && activeMenu == 'stage_changes'" md="9">
        <StageDetail class="stage-detail pb-3" :isSaveEnabled="isSaveEnabled" :selectedItem="selectedDetail"
        @save-form-click="stageDetailSave" @form-stage-block-change="checkFormChangeHandler" />
        <JobTable :isEnabled="selectedDetail.isEnabled"/>
      </b-col>
      <b-col v-else-if="activeIndex >= 0 && activeMenu == 'interview'" md="9">
        <InterviewDetail ref="interviewDetail" class="stage-detail pb-3" :isSaveEnabled="isSaveEnabled" :selectedItem="selectedDetail"
        @save-form-click="stageDetailSave" @form-interview-block-change="checkInterviewFormChangeHandler" />
        <JobTable :isEnabled="selectedDetail.isEnabled"/>
      </b-col>
      <b-col v-else md="9" class="text-center">
        <div class="d-flex align-items-center justify-content-between p-2 pl-3 pb-2 stage-title-main no-stage">
          <div class="stage-title"></div>
        </div>
        <div class="mt-4">
          {{ 'Select the Stage you want to work on' }}
        </div>
      </b-col>
    </b-row>
    <confirmation-modal ref="saveStageChangesModal" header="Save changes" cancelBtnText="Cancel"
    question="You have made changes to this stage. Do you want to save before you leave?"
    @on-accept="saveStageChanges" @on-reject="changeStageTemplate" confirmBtnText="Save" />
    <confirmation-modal ref="saveInterviewChangesModal" header="Save changes" cancelBtnText="Cancel"
    question="You have made changes to this stage. Do you want to save before you leave?"
    @on-accept="saveInterviewChanges" @on-reject="changeInterviewTemplate" confirmBtnText="Save" />

    <!-- Stage Detail modals -->
    <confirmation-modal ref="stageApplyModal" header="Apply to all?"
    question="Apply this new automation to all the existing jobs that contain this stage?"
    cancelBtnText="No" confirmBtnText="Apply to all jobs" confirmBtnType="primary"
    :isClose="true" @on-accept="applyAllJob" @on-reject="cancelApplyAllJob" />
    <confirmation-modal ref="stageTurnOnModal" header="Turn on this automation?"
    question="Now that you’ve configured this automation, would you like to turn it on?"
    cancelBtnText="Keep off" confirmBtnText="Turn on" confirmBtnType="primary"
    :isClose="true" @on-accept="turnOnApplyJob" @on-reject="keepOffApplyJob" />
  </div>
</template>
<script>
import StageDetail from './StageDetail.vue';
import InterviewDetail from '../interviews/InterviewDetail.vue';
import JobTable from './JobTable.vue';
import NoIntegrate from './NoIntegrate.vue';
import ConfirmationModal from '@/components/App/modals/ConfirmationModal.vue';

export default {
  components: {
    StageDetail,
    JobTable,
    NoIntegrate,
    InterviewDetail,
    ConfirmationModal,
  },
  props: {
    activeMenu: String,
  },
  data() {
    return {
      items: [
        {
          id: 1,
          name: 'Warehouse',
          jobTitle: 'Phone Screen',
          jobs: '50 jobs',
          message: '',
          hours: 1,
          isEnabled: false,
        },
        {
          id: 2,
          name: 'Office',
          jobTitle: 'Phone Screen',
          jobs: '50 jobs',
          message: '',
          hours: 2,
          isEnabled: false,
        },
        {
          id: 3,
          name: 'Office',
          jobTitle: 'Phone Screen',
          jobs: '50 jobs',
          message: '',
          hours: 2,
          isEnabled: false,
        },
        {
          id: 4,
          name: 'Office',
          jobTitle: 'Phone Screen',
          jobs: '50 jobs',
          message: '',
          hours: 2,
          isEnabled: false,
        },
        {
          id: 5,
          name: 'Office',
          jobTitle: 'Phone Screen',
          jobs: '50 jobs',
          message: '',
          hours: 2,
          isEnabled: false,
        },
        {
          id: 6,
          name: 'Office',
          jobTitle: 'Phone Screen',
          jobs: '50 jobs',
          message: '',
          hours: 2,
          isEnabled: false,
        },
        {
          id: 7,
          name: 'Office',
          jobTitle: 'Phone Screen',
          jobs: '50 jobs',
          message: '',
          hours: 2,
          isEnabled: false,
        },
        {
          id: 8,
          name: 'Office',
          jobTitle: 'Phone Screen',
          jobs: '50 jobs',
          message: '',
          hours: 2,
          isEnabled: false,
        },
        {
          id: 9,
          name: 'Office',
          jobTitle: 'Phone Screen',
          jobs: '50 jobs',
          message: '',
          hours: 2,
          isEnabled: false,
        },
        {
          id: 10,
          name: 'Office',
          jobTitle: 'Phone Screen',
          jobs: '50 jobs',
          message: '',
          hours: 2,
          isEnabled: false,
        },
      ],
      activeIndex: -1, // Initially first card is active
      selectedDetail: null, // To store the selected item's detail
      switchState: false,
      formBlocked: false,
      unSavedFormData: null,
      formInterviewBlocked: false,
      unsavedInterviewFormData: null,
      tempIndex: null,
      tempInterviewIndex: null,
      isSaveEnabled: false,
    };
  },
  computed: {
    isLastItem: function() {
      return (index) => index === this.items.length - 1;
    },
    isFirstItem: function() {
      return (index) => index === 0;
    },
  },
  methods: {
    toggleActive: function(index, event) {
      if (this.formBlocked && index != this.activeIndex) {
        this.$refs.saveStageChangesModal.openModal(index, this.unSavedFormData);
        return true;
      }
      if (this.formInterviewBlocked && index != this.activeIndex) {
        this.$refs.saveInterviewChangesModal.openModal(index, this.unsavedInterviewFormData);
        return true;
      }
      if (event.target.tagName == 'INPUT') {
        this.$set(this.items, index, {...this.items[index], isEnabled: !this.items[index].isEnabled});
        if (this.unSavedFormData && this.unSavedFormData.id === this.items[index].id) {
          this.$set(this.items, index, {...this.items[index], message: this.unSavedFormData.message});
        }
        this.selectedDetail = this.items[index];
        return true;
      }
      if (this.$refs.interviewDetail && index != this.activeIndex) {
        this.$refs.interviewDetail.interviewOptions.map((item)=>{
          item.isSelected = false;
          item.message = '';
        });
        this.$refs.interviewDetail.selectedOptionIndex = 0;
        this.$refs.interviewDetail.selectedOption = this.$refs.interviewDetail.interviewOptions[0];
      }
      this.activeIndex = index; // Activate clicked card
      this.selectedDetail = this.items[index];
    },
    checkFormChangeHandler: function({blocked, unSavedFormData}) {
      this.formBlocked = blocked;
      this.unSavedFormData = unSavedFormData;
      this.isSaveEnabled = true;
    },
    saveStageChanges: function(index) {
      this.tempIndex = index;
      this.stageDetailSave();
      // this.items.map((item)=>{
      //   item.isEnabled = false;
      // });
      // this.formBlocked = false;
      // this.activeIndex = index;
      // this.selectedDetail = this.items[index];
    },
    changeStageTemplate: function(index) {
      this.items.map((item)=>{
        item.isEnabled = false;
      });
      this.activeIndex = index;
      this.selectedDetail = this.items[index];
      this.formBlocked = false;
    },
    checkInterviewFormChangeHandler: function({blocked, unSavedFormData}) {
      this.formInterviewBlocked = blocked;
      this.unsavedInterviewFormData = unSavedFormData;
      this.isSaveEnabled = true;
    },
    changeInterviewTemplate: function(index) {
      this.items.map((item)=>{
        item.isEnabled = false;
      });
      this.activeIndex = index;
      this.selectedDetail = this.items[index];
      this.formInterviewBlocked = false;
      this.$refs.interviewDetail.selectedOption = this.$refs.interviewDetail.interviewOptions[0];
      this.$refs.interviewDetail.selectedOptionIndex = 0;
      this.$refs.interviewDetail.interviewOptions.map((item)=>{
        item.isSelected = false;
        item.message = '';
      });
      this.isSaveEnabled = false;
    },
    saveInterviewChanges: function(index) {
      this.tempInterviewIndex = index;
      this.stageDetailSave();
      // this.items.map((item)=>{
      //   item.isEnabled = false;
      // });
      // this.formInterviewBlocked = false;
      // this.activeIndex = index;
      // this.selectedDetail = this.items[index];
      // this.$refs.interviewDetail.selectedOption = this.$refs.interviewDetail.interviewOptions[0];
      // this.$refs.interviewDetail.selectedOptionIndex = 0;
      // this.$refs.interviewDetail.interviewOptions.map((item)=>{
      //   item.isSelected = false;
      //   item.message = '';
      // });
    },
    turnOnApplyJob: function() {
      this.selectedDetail.isEnabled = true;
      this.$refs.stageApplyModal.openModal();
    },
    stageDetailSave: function() {
      if (this.selectedDetail.isEnabled) {
        this.$refs.stageApplyModal.openModal();
      } else {
        this.$refs.stageTurnOnModal.openModal();
      }
    },
    keepOffApplyJob: function() {
      if (this.$refs.interviewDetail) {
        if (this.tempInterviewIndex) {
          this.selectedDetail = this.items[this.tempInterviewIndex];
          this.activeIndex = this.tempInterviewIndex;
        }
        this.$refs.interviewDetail.selectedOption = this.$refs.interviewDetail.interviewOptions[0];
        this.$refs.interviewDetail.selectedOptionIndex = 0;
        this.$refs.interviewDetail.interviewOptions.map((item)=>{
          item.isSelected = false;
          item.message = '';
        });
      } else {
        if (this.tempIndex) {
          this.selectedDetail = this.items[this.tempIndex];
          this.activeIndex = this.tempIndex;
        }
      }
      this.tempIndex = null;
      this.tempInterviewIndex = null;
      this.formBlocked = false;
      this.unSavedFormData = null;
      this.formInterviewBlocked = false;
      this.unsavedInterviewFormData = null;
      this.isSaveEnabled = false;
    },
    applyAllJob: function() {
      if (this.tempIndex) {
        this.items.map((item)=>{
          // item.isEnabled = false;
          // item.message = '';
        });
        this.activeIndex = this.tempIndex;
        this.selectedDetail = this.items[this.tempIndex];
        this.tempIndex = null;
      }
      if (this.tempInterviewIndex) {
        this.items.map((item)=>{
          // item.isEnabled = false;
          // item.message = '';
        });
        this.activeIndex = this.tempInterviewIndex;
        this.selectedDetail = this.items[this.tempInterviewIndex];
        this.tempInterviewIndex = null;
        if (this.$refs.interviewDetail) {
          this.$refs.interviewDetail.selectedOption = this.$refs.interviewDetail.interviewOptions[0];
          this.$refs.interviewDetail.selectedOptionIndex = 0;
          this.$refs.interviewDetail.interviewOptions.map((item)=>{
            item.isSelected = false;
            item.message = '';
          });
        }
      }
      this.formBlocked = false;
      this.unSavedFormData = null;
      this.formInterviewBlocked = false;
      this.unsavedInterviewFormData = null;
      this.isSaveEnabled = false;
      // this.selectedDetail.message = '';
      // this.selectedDetail.isEnabled = false;
      // if (this.$refs.interviewDetail) {
      //   this.$refs.interviewDetail.selectedOption = this.$refs.interviewDetail.interviewOptions[0];
      //   this.$refs.interviewDetail.selectedOptionIndex = 0;
      //   this.$refs.interviewDetail.interviewOptions.map((item)=>{
      //     item.isSelected = false;
      //     item.message = '';
      //   });
      // }
    },
    cancelApplyAllJob: function() {
      this.formBlocked = false;
      this.unSavedFormData = null;
      this.formInterviewBlocked = false;
      this.unsavedInterviewFormData = null;
      this.isSaveEnabled = false;
      // this.selectedDetail.message = '';
      // this.selectedDetail.isEnabled = false;
      // if (this.$refs.interviewDetail) {
      //   this.$refs.interviewDetail.selectedOption = this.$refs.interviewDetail.interviewOptions[0];
      //   this.$refs.interviewDetail.selectedOptionIndex = 0;
      //   this.$refs.interviewDetail.interviewOptions.map((item)=>{
      //     item.isSelected = false;
      //     item.message = '';
      //   });
      // }
    },
  },
  mounted: function() {
    const noStageElement = document.getElementsByClassName('no-stage')[0];
    if (noStageElement) {
      noStageElement.style.height = this.$refs.stageTitle.clientHeight+1+'px';
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/argon.scss';
.stage-card{
  border-radius: 0px;
  border-right: 0;
}
.stage-list {
  max-height: 80vh;
  overflow-y: auto;
  height: 90vh;
}

.no-integrate {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 1.2px;
}

.active {
  border-left: 4px solid $primary;
  background-color: #EDF8FF;

  .name {
    color: $primary;
  }
}

.plus-icon {
  color: $primary;
}

.stage-list-data {
  height: fit-content;
  border: 0px;
  border-right: 1px solid #EFEFEF;
  border-top: 0;
  margin-bottom: -15px;
}
.stage-detail{
 border-bottom: 1px solid #EFEFEF;
}
.custom-switch{
  width: 1rem !important;
}
.first-item {
  border-top: 0;
}
.no-stage {
  height: 37px;
}
.bullet-list::before {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 5px;
  height: 5px;
  background-color: #435451;
  margin: 0 6px;
  border-radius: 50%;
  opacity: 0.3;
  content: "•";
  color: #435451;
}

</style>
